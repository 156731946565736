import React, { useState, useEffect } from "react";
import {
  ActionIcon,
  Button,
  Select,
  Switch,
  TextInput,
  Group,
  Flex,
} from "@mantine/core";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import { IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";

import { getUuid } from "@util/getUuid";
import { fieldTypeDefs, fieldTypes } from "./helpers";
import { StyledTextButton } from "./EffortFields";
import { CustomTooltip } from "@components/shared";

const initialFormState = {
  api_field_name: "--",
  options: [],
  // name: getUuid(),
  label: "",
  required: false,
  multiple: false,
  reportable: false,
  default_checked: false,
  label_url: "",
};

const generateOption = () => ({
  text: "text",
  api_field_value: "--",
  value: getUuid(),
  uuid: getUuid(),
});

function translateEngagementItem(item) {
  return {
    allow_multiple_responses: item.multiple,
    api_field_name: item.api_field_name,
    interrogative: item.label,
    reportable: item.reportable,
    required: item.required,
  };
}

const FieldForm = ({
  formState,
  effortId,
  engagementItemId,
  onSubmit,
  hasImageField,
}) => {
  const [fieldInfo, setFieldInfo] = useState(initialFormState);
  const isAdmin = useSelector((state) => state.admin) ? true : false;
  useEffect(() => {
    if (!formState) return;

    setFieldInfo(formState);
  }, []);

  function onSubmitClick() {
    if (fieldInfo.db_property) {
      return onSubmit(fieldInfo);
    }

    if (engagementItemId) {
      return onUpdate();
    }

    // if (formState) {
    //   return onSuccess();
    // }

    const req = {
      ...translateEngagementItem(fieldInfo),
      effort_id: effortId,
      type: fieldInfo.type,
    };

    if (fieldInfo.options) {
      req.select_items = fieldInfo.options.map((m) => ({
        api_field_value: m.api_field_value,
        uuid: m.uuid,
        label: m.text,
      }));
    } else {
      req.select_items = [];
    }

    axios
      .post(`/engagement-items/`, req)
      .then(({ data }) => {
        onSuccess(
          data.response[0].engagement_item_id,
          data.response[0].select_items
        );
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onUpdate(newFieldInfo) {
    const baseFieldInfo = newFieldInfo ? newFieldInfo : fieldInfo;

    const req = {
      ...translateEngagementItem(baseFieldInfo),
      engagement_item_id: engagementItemId,
    };

    if (baseFieldInfo.options) {
      req.select_items = baseFieldInfo.options.map((m) => ({
        ...m,
        error_string: null,
        label: m.text,
      }));
    } else {
      req.select_items = [];
    }

    axios
      .put(`/engagement-items/${engagementItemId}/`, req)
      .then(({ data }) => {
        onSuccess(engagementItemId, data.response[0].select_items);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onSuccess(id, selectItems) {
    onSubmit({
      ...fieldInfo,
      options: selectItems
        ? selectItems.map((m) => ({
            ...m,
            id: m.id,
            value: m.id,
            text: m.label,
            uuid: m.uuid,
          }))
        : [],
      name: id,
      value: fieldInfo.multiple ? [] : fieldInfo.value,
      engagement_item_id: fieldInfo.engagement_item_id || id,
    });
    setFieldInfo(initialFormState);
  }

  function onOptionChange(optInfo) {
    const newFieldInfo = { ...fieldInfo };
    const newOptions = [...newFieldInfo.options];
    const opt = newOptions.find((f) => f.uuid === optInfo.uuid);
    if (!opt) return;
    opt[optInfo.key] = optInfo.value;
    setFieldInfo({ ...fieldInfo, options: newOptions });
  }

  function onOptionRemove(optUuid, optId, dbValue) {
    // db value is for when the field is a db_property and the select
    // item lacks uuid & id
    const newFieldInfo = { ...fieldInfo };

    if (dbValue) {
      newFieldInfo.options = newFieldInfo.options.filter(
        (f) => f.value !== dbValue
      );
    } else if (optId) {
      newFieldInfo.options = newFieldInfo.options.filter(
        (f) => f.value !== optId
      );
    } else {
      newFieldInfo.options = newFieldInfo.options.filter(
        (f) => f.uuid !== optUuid
      );
    }

    // seems old
    // newFieldInfo.options = newFieldInfo.options.filter(
    //   (f) => f.uuid !== optUuid
    // );

    if (optId) {
      onUpdate(newFieldInfo);
    }
    setFieldInfo(newFieldInfo);
  }

  function onOptionAdd() {
    const newFieldInfo = { ...fieldInfo };
    newFieldInfo.options = [...newFieldInfo.options, generateOption()];
    setFieldInfo(newFieldInfo);
  }

  const optionsMissingValues =
    fieldInfo.type === "checkbox"
      ? false
      : !fieldInfo.options
      ? false
      : fieldInfo.options.find((f) => !f.text) ||
        (!fieldInfo.db_property &&
          fieldInfo.options.find((f) => !f.api_field_value))
      ? true
      : false;

  return (
    <div>
      <TextInput
        label="Label"
        value={fieldInfo.label}
        onChange={(e) => setFieldInfo({ ...fieldInfo, label: e.target.value })}
      />
      {!fieldInfo.db_property && (
        <TextInput
          label="MIXER Admin Use Only"
          value={fieldInfo.api_field_name}
          onChange={(e) =>
            setFieldInfo({ ...fieldInfo, api_field_name: e.target.value })
          }
        />
      )}
      <Switch
        mt="md"
        label="Required"
        checked={fieldInfo.required}
        onChange={() =>
          setFieldInfo({ ...fieldInfo, required: !fieldInfo.required })
        }
        mb="sm"
        disabled={[
          "first_name",
          "last_name",
          "mobile_phone",
          "email",
          "zip",
          "marketing_opt_in",
        ].includes(fieldInfo.db_property)}
      />
      <CustomTooltip label="If toggled, a summary of the answers to this question will be visible in Reports under the effort name.">
        <Switch
          label="Include in reports"
          checked={fieldInfo.reportable}
          onChange={() =>
            setFieldInfo({ ...fieldInfo, reportable: !fieldInfo.reportable })
          }
          disabled={
            [fieldTypeDefs.text, fieldTypeDefs.textarea].includes(
              fieldInfo.type
            )
              ? true
              : false
          }
        />
      </CustomTooltip>
      {!fieldInfo.db_property && (
        <Select
          label="Type of Field"
          mt="xs"
          value={fieldInfo.type}
          onChange={(e) =>
            setFieldInfo({
              ...fieldInfo,
              type: e,
              options: [fieldTypeDefs.select, fieldTypeDefs.radio].includes(e)
                ? fieldInfo.options.length
                  ? fieldInfo.options
                  : [generateOption()]
                : [],
              reportable: [fieldTypeDefs.text, fieldTypeDefs.textarea].includes(
                e
              )
                ? false
                : fieldInfo.reportable,
            })
          }
          placeholder="Select one..."
          data={fieldTypes
            .filter((f) =>
              !isAdmin ? ![fieldTypeDefs.image].includes(f.value) : true
            )
            .map((m) => ({
              ...m,
              disabled:
                m.value === fieldTypeDefs.image && hasImageField ? true : false,
            }))}
          searchable
          disabled={formState && !formState.editable}
        />
      )}
      {fieldTypeDefs.select === fieldInfo.type && !fieldInfo.db_property && (
        <React.Fragment>
          <Switch
            mt="md"
            label="Allow Multiple Selections"
            checked={fieldInfo.multiple}
            onChange={() =>
              setFieldInfo({ ...fieldInfo, multiple: !fieldInfo.multiple })
            }
          />
        </React.Fragment>
      )}
      {fieldTypeDefs.checkbox === fieldInfo.type && (
        <React.Fragment>
          <Switch
            mt="md"
            label="Checked by default"
            checked={fieldInfo.default_checked}
            onChange={() =>
              setFieldInfo({
                ...fieldInfo,
                default_checked: !fieldInfo.default_checked,
              })
            }
            mb="sm"
          />
          <TextInput
            label="URL for label (optional, will open in new window)"
            mb="lg"
            value={fieldInfo.label_url}
            onChange={(e) =>
              setFieldInfo({
                ...fieldInfo,
                label_url: e.target.value,
              })
            }
          />
        </React.Fragment>
      )}
      {[fieldTypeDefs.select, fieldTypeDefs.radio].includes(fieldInfo.type) && (
        <React.Fragment>
          <OptionList
            editable={formState && formState.editable === false ? false : true}
            options={fieldInfo.options}
            onChange={onOptionChange}
            onRemoveSuccess={onOptionRemove}
            onDbOptionRemove={(val) => onOptionRemove(null, null, val)}
            onAddClick={onOptionAdd}
          />
        </React.Fragment>
      )}
      <Button
        onClick={onSubmitClick}
        mt="sm"
        mb="sm"
        disabled={
          !fieldInfo.label ||
          !fieldInfo.type ||
          (!fieldInfo.api_field_name && !fieldInfo.db_property) ||
          optionsMissingValues
        }
        size="sm"
        fullWidth
      >
        {formState ? "Update" : "Add Field"}
      </Button>
    </div>
  );
};

export default FieldForm;

const OptionList = ({
  editable,
  options = [],
  onChange,
  onRemoveSuccess,
  onDbOptionRemove,
  onAddClick,
}) => {
  function onRemoveClick(opt) {
    if (!opt.id && !opt.uuid) {
      return onDbOptionRemove(opt.value);
    }
    if (!opt.id) return onRemoveSuccess(opt.uuid);

    const req = {
      select_item_id: opt.id,
    };

    axios
      .post(`/select-items/${opt.id}/remove/`, req)
      .then(() => {
        onRemoveSuccess(opt.uuid, opt.id);
      })
      .catch((err) => {
        toast.error(err);
        window.location.reload();
      });
  }

  return (
    <StyledOptions>
      <Flex gap="xs">
        <h5 style={{ margin: "1em 0 0.25em 0", flexGrow: 1 }}>Options</h5>
        {editable && (
          <h5 style={{ margin: "1em 0 0.25em 0", flexGrow: 1 }}>
            API Field Value
          </h5>
        )}
      </Flex>
      {options.map((opt) => (
        <StyledOption key={opt.uuid}>
          <Flex gap="xs">
            <TextInput
              placeholder="label"
              value={opt.text}
              disabled={!editable}
              onChange={(e) =>
                onChange({ uuid: opt.uuid, key: "text", value: e.target.value })
              }
              style={{ flexGrow: 1 }}
            />
            {editable && (
              <TextInput
                placeholder="api field value"
                style={{ flexGrow: 1 }}
                value={opt.api_field_value}
                disabled={!editable}
                onChange={(e) =>
                  onChange({
                    uuid: opt.uuid,
                    key: "api_field_value",
                    value: e.target.value,
                  })
                }
              />
            )}
          </Flex>
          {options.length > 1 && (
            <div className="actions">
              <ActionIcon
                onClick={() => onRemoveClick(opt)}
                size="sm"
                mt="sm"
                radius="xl"
                style={{ marginTop: "0" }}
                variant="light"
                color="red"
              >
                <IconTrash style={{ width: "70%", height: "70%" }} />
              </ActionIcon>
            </div>
          )}
          {opt.error_string && (
            <p className="option-error">{opt.error_string}</p>
          )}
        </StyledOption>
      ))}
      {editable && (
        <Button variant="light" fullWidth mt="sm" mb="sm" onClick={onAddClick}>
          Add Selection
        </Button>
      )}
    </StyledOptions>
  );
};

const StyledOptions = styled.div`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledOption = styled.div`
  flex-grow: 1;
  & + & {
    margin-top: 1em;
  }

  .actions {
    margin-top: 0.25em;
    display: flex;
    justify-content: flex-end;
  }

  /* .actions {
    width: 10%;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  p.option-error {
    color: red;
    font-weight: bold;
  } */
`;
