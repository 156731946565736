import React, { useEffect } from "react";
import * as Yup from "yup";

import { BasicForm } from "@components/shared";
import { fieldTemplates } from "./fieldLib";
import { dateValidation, phoneValidation } from "@util/validation";

export default function EffortBuilderForm({
  buttonText = "Save",
  fields,
  loading = false,
  onSubmit,
}) {
  useEffect(() => {}, [JSON.stringify(fields)]);

  const countryField = fields.find(
    (f) => f.db_property === "country" && f.type === "select"
  );
  const isCanadianForm =
    countryField?.options.length === 1 &&
    countryField?.options.find((f) => f.text === "CA")
      ? true
      : false;
  const isAmericanForm =
    countryField?.options.length === 1 &&
    countryField?.options.find((f) => f.text === "US")
      ? true
      : false;

  const formattedFields = fields
    .filter((f) => fieldTemplates.find((ff) => ff.name === f.type))
    .map((responseField) => {
      const templateField = fieldTemplates.find(
        (ff) => ff.name === responseField.type
      );
      const obj = { ...templateField };
      obj.required = responseField.required;
      obj.label = responseField.label;
      if (responseField.initialValue) {
        obj.initialValue = responseField.initialValue;
      }
      if (obj.name === "checkbox") {
        obj.checkbox = true;
        obj.initialValue = responseField.default_checked || false;
        if (
          responseField.db_property &&
          responseField.db_property === "marketing_opt_in"
        ) {
          obj.required = false;
          obj.initialValue = true;
        }
        if (responseField.label_url) {
          obj.labelUrl = responseField.label_url;
        }
      }
      if (obj.name === "date") {
        obj.datepicker = true;
        obj.initialValue = "";
      }
      obj.name =
        responseField.db_property || responseField.name || templateField.name;
      obj.schema = () =>
        responseField.required
          ? templateField.schema.required("Required")
          : templateField.schema;
      if (
        obj.label.toLowerCase() === "email" ||
        responseField.db_property === "email"
      ) {
        if (responseField.required) {
          obj.schema = () =>
            Yup.string().email("Enter a valid email address").required();
        } else {
          obj.schema = () => Yup.string().email("Enter a valid email address");
        }
      }
      if (responseField.options && responseField.options.length) {
        obj.options = responseField.options.map((m) => {
          return {
            label: m.text,
            value: m.value ? `${m.value}` : `${m.uuid}`,
            key: m.value ? m.value : m.uuid,
          };
        });
        if (responseField.multiple) {
          obj.multiple = true;
          obj.schema = !responseField.required
            ? () => null
            : () =>
                Yup.array().min(1, "Select at least one").required("Required");
        } else {
          obj.multiple = false;
        }
      }
      if (obj.name === "zip" || responseField.db_property === "zip") {
        const zipRegex = /^[0-9]{5}$|^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        const usZipRegex = /^[0-9]{5}$/;
        const canadianZipRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        obj.schema = () =>
          Yup.string()
            .matches(
              isAmericanForm
                ? usZipRegex
                : isCanadianForm
                ? canadianZipRegex
                : zipRegex,
              `Not a valid ${
                isCanadianForm
                  ? "postal code"
                  : isAmericanForm
                  ? "zip"
                  : "zip/postal code"
              }`
            )
            .required("Required");
        obj.zip = true;
      }
      if (responseField.db_property === "mobile_phone") {
        obj.mask = {
          pattern: "(999) 999-9999",
          placeholder: "(XXX) XXX-XXXX",
        };
        const baseSchema = Yup.string().matches(
          phoneValidation,
          "Not a phone number"
        );
        obj.schema = () =>
          responseField.required ? baseSchema.required() : baseSchema;
      }
      if (responseField.db_property === "address_1") {
        obj.addressLookup = true;
        // obj.ignoreZip = true;
        obj.addressKeys = {
          state: "state_province",
        };
        if (countryField) {
          obj.addressLookupCountries = countryField.options.map((m) =>
            m.text.toLowerCase()
          );
        }
      }
      if (responseField.db_property === "state_province") {
        obj.select = true;
        obj.options = [...obj.options].map((m) => ({
          ...m,
          value: m.label,
        }));
        // obj.label = "State";
      }
      if (responseField.db_property === "country" && obj.select) {
        obj.options = [...obj.options].map((m) => ({
          ...m,
          value: m.label,
        }));
      }
      if (
        obj.label === "Birthdate" ||
        responseField.db_property === "birthdate"
      ) {
        const baseSchema = Yup.string().matches(
          dateValidation,
          "Not a valid date"
        );
        obj.schema = () =>
          responseField.required ? baseSchema.required() : baseSchema;
        obj.mask = {
          pattern: "99/99/9999",
          placeholder: "XX/XX/XXXX",
        };
      }

      return obj;
    });

  return (
    <React.Fragment>
      <BasicForm
        buttonText={buttonText}
        fields={formattedFields}
        onSubmit={onSubmit}
        submitDisabled={loading}
        loading={loading}
        buttonProps={{
          fullWidth: true,
          // color: "brand",
        }}
      />
    </React.Fragment>
  );
}
