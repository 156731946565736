import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Button,
  Alert,
  Card,
  Flex,
  Box,
  Switch,
  Title,
  Tabs,
} from "@mantine/core";
import { useParams, useMatch, Route, Routes } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { EmailServerCreate } from "@components/EmailServer";
import {
  BulkCommunicationManagement,
  BulkCommunicationAllowToggle,
} from "@components/BulkCommunication";
import {
  LocationContesting,
  LocationForm,
  LocationReadOnly,
  LocationSettings,
} from "@components/Location";
import { AssetManagement, LogoUpload } from "@components/Asset";
import { CampaignManagement } from "@components/Campaign";
import { EffortManagement } from "@components/Effort";
import {
  EntitySettingsCode,
  ErrorView,
  PageTitle,
  QrCodeGenerator,
  RequestApiConnectionButton,
  Subnav,
} from "@components/shared";
import { GeospotManagement } from "@components/Geospot";
import { KeywordManagement } from "@components/Keyword";
import { LootLockerManagement } from "@components/LootLocker";
import { ManagerManagement } from "@components/Manager";
import { StationPlays, StationFeedLoaderButton } from "@components/Station";
import { SongQueryManagement } from "@components/SongQuery";
import { MessageList } from "@components/Message";
import { EmailAppConfig } from "@components/AppConfig";

import { locationVariety } from "@components/Location/helpers";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function LocationDetail({ isProfile = false, isAdmin = false }) {
  const queryClient = useQueryClient();
  const params = useParams();
  const managerInfo = useSelector((state) => state.manager);
  const { id } = params;
  const queryKey = `locations${id}`;

  const { failureReason, data: info } = useQuery({
    queryKey: [queryKey],
    queryFn: async () =>
      axios
        .get(`/locations/${params.id}/`)
        .then(({ data }) => {
          return data.response[0];
        })
        .catch((err) => {
          throw err;
        }),
  });

  const fetchData = () => queryClient.invalidateQueries([queryKey]);

  if (failureReason) {
    return <ErrorView text={failureReason} />;
  }

  if (!info) return null;

  const showTextOnly = managerInfo && info.text_only;

  const postmarkServerId =
    info.postmark_servers && JSON.stringify(info.postmark_servers) !== "{}"
      ? Object.keys(info.postmark_servers)[0]
      : null;

  const links = [
    { to: `/locations/${id}`, text: "Detail" },
    { to: `/locations/${id}/campaigns`, text: "Campaigns" },
    { to: `/locations/${id}/efforts`, text: "Efforts" },
    { to: `/locations/${id}/contesting`, text: "Contesting" },
    { to: `/locations/${id}/managers`, text: "Managers" },
    { to: `/locations/${id}/keywords`, text: "Keywords" },
    // { to: `/locations/${id}/assets`, text: "Assets" },
    { to: `/locations/${id}/asset-library`, text: "Asset Library" },
    { to: `/locations/${id}/geospots`, text: "Geo-Spots" },
    { to: `/locations/${id}/play`, text: "Play" },
    { to: `/locations/${id}/song-categories`, text: "Song Categories" },
    // { to: `/locations/${id}/email`, text: "Email" },
    { to: `/postmark/${postmarkServerId}`, text: "Email" },
    { to: `/locations/${id}/look-and-feel`, text: "Look & Feel" },
    { to: `/locations/${id}/settings`, text: "Settings" },
    { to: `/locations/${id}/loot-locker`, text: "Loot Locker" },
    { to: `/locations/${id}/messages`, text: "Messages" },
    { to: `/locations/${id}/bulk-communication`, text: "Bulk Communication" },
  ]
    .filter((f) =>
      !postmarkServerId
        ? !["Email", "Bulk Communication"].includes(f.text)
        : true
    )
    .filter((f) =>
      !info.allow_email_marketing
        ? !["Bulk Communication"].includes(f.text)
        : true
    )
    .filter((f) =>
      isProfile
        ? ![
            "Asset Library",
            "Campaigns",
            "Contesting",
            "Efforts",
            "Email",
            "Keywords",
            "Loot Locker",
            "Managers",
            "Messages",
            "Settings",
            // "Geo-Spots",
            // "Look & Feel",
          ].includes(f.text)
        : true
    )
    .filter((f) =>
      isProfile && managerInfo.location_id
        ? !["Bulk Communication"].includes(f.text)
        : true
    )
    .filter((f) =>
      info.variety !== locationVariety.station
        ? !["Play", "Song Categories", "Look & Feel"].includes(f.text)
        : true
    );

  const FormattedLocationForm = () => (
    <LocationForm
      address1={info.address_1}
      address2={info.address_2}
      allowCampaignCreate={info.allow_campaign_create}
      callLetters={info.call_letters}
      city={info.city}
      country={info.country}
      feedLoaderFilename={info.feed_loader_filename}
      frequency={info.frequency}
      genre={info.genre}
      id={params.id}
      name={info.name}
      onSuccess={fetchData}
      otpDisplayName={info.otp_display_name}
      state={info.state_province}
      variety={info.variety}
      textOnly={info.text_only}
      thirdPartyIdent={info.third_party_ident}
      timezone={info.timezone}
      zip={info.zip}
    />
  );

  if (!info.edit) {
    return (
      <React.Fragment>
        <PageTitle
          title={info.name}
          subtitle={<React.Fragment>Location</React.Fragment>}
          icon={entityIcon.location()}
          iconColor={entityColor.location}
          style={{ marginBottom: "2em" }}
        />
        <LocationReadOnly info={info} />
      </React.Fragment>
    );
  }

  if (
    info.variety === 1 &&
    (!info.call_letters || !info.frequency || !info.genre)
  ) {
    return (
      <React.Fragment>
        <Flex mb={info.settings_code ? 0 : "lg"}>
          <LogoUpload
            locationId={id}
            onSuccess={fetchData}
            url={info.logo_url}
          />
          <PageTitle
            title={info.name}
            subtitle={<React.Fragment>Location</React.Fragment>}
            icon={entityIcon.location()}
            iconColor={entityColor.location}
            style={{ marginBottom: "0" }}
          />
        </Flex>
        <Alert title="More info needed!" color="yellow" mb="sm">
          Add call letters, frequency, and genre to this location to access
          additional features
        </Alert>
        <Card>
          <FormattedLocationForm />
        </Card>
      </React.Fragment>
    );
  }

  return (
    <div>
      {info.third_party_ident && managerInfo && (
        <Box mb="lg">
          <RequestApiConnectionButton locationId={id} />
        </Box>
      )}
      <Flex mb={info.settings_code ? 0 : "lg"}>
        <LogoUpload locationId={id} onSuccess={fetchData} url={info.logo_url} />
        <PageTitle
          title={info.name}
          subtitle={<React.Fragment>Location</React.Fragment>}
          icon={entityIcon.location()}
          iconColor={entityColor.location}
          style={{ marginBottom: "0", marginLeft: "0.8em" }}
        />
      </Flex>
      {!showTextOnly && (
        <Flex mt="sm">
          <Subnav buttonColor={entityColor.location} links={links} />
          {info.settings_code && info.variety !== locationVariety.dealer && (
            <Button
              href={`/user-login/${info.settings_code.code}`}
              component="a"
              target="_blank"
              variant="subtle"
              size="xs"
              mb="lg"
              color="gray"
            >
              User Portal
            </Button>
          )}
        </Flex>
      )}
      {showTextOnly ? (
        <Card mt="sm">
          <FormattedLocationForm />
        </Card>
      ) : (
        <Routes>
          <Route
            exact
            path="/"
            element={
              <React.Fragment>
                <Card>
                  <FormattedLocationForm />
                </Card>
                {isAdmin && info.status && (
                  <Card mt="lg">
                    <LocationStatus
                      locationId={id}
                      fetchData={fetchData}
                      status={info.status}
                    />
                  </Card>
                )}
                {!postmarkServerId && isAdmin && (
                  <EmailServerCreate fetchData={fetchData} locationId={id} />
                )}
                {isAdmin && (
                  <Card mt="lg">
                    <BulkCommunicationAllowToggle
                      fetchData={fetchData}
                      locationId={id}
                      value={info.allow_email_marketing}
                      disabled={postmarkServerId ? false : true}
                    />
                  </Card>
                )}
                {info.microsite_gallery_code && (
                  <>
                    <Title mt="xl" mb="xs" order={4}>
                      Gallery QR Code
                    </Title>
                    <Card>
                      <QrCodeGenerator
                        text={`${import.meta.env.VITE_DOMAIN}/gallery/${
                          info.microsite_gallery_code
                        }`}
                      />
                    </Card>
                  </>
                )}
              </React.Fragment>
            }
          />
          <Route
            path="loot-locker"
            element={
              <div>
                <LootLockerManagement locationId={id} />
              </div>
            }
          />
          {!isProfile && [
            <Route
              path="campaigns"
              element={
                <React.Fragment>
                  <CampaignManagement locationId={id} />
                </React.Fragment>
              }
            />,
            <Route
              path="contesting"
              element={
                <React.Fragment>
                  <LocationContesting locationId={id} />
                </React.Fragment>
              }
            />,
            <Route
              path="managers"
              element={
                <div>
                  <ManagerManagement locationId={id} />
                </div>
              }
            />,
            <Route
              path="keywords"
              element={
                <div>
                  <KeywordManagement locationId={id} />
                </div>
              }
            />,
            <Route
              path="messages"
              element={
                <div>
                  <MessageList locationId={id} />
                </div>
              }
            />,
            // <Route
            //   path="email"
            //   element={
            //     <div>
            //       <EmailServerEntityManagement
            //         locationId={id}
            //         fetchData={fetchData}
            //         postmarkServerId={info.postmark_server}
            //         postmarkServers={info.postmark_servers}
            //       />
            //     </div>
            //   }
            // />,
            <Route
              path="settings"
              element={
                <React.Fragment>
                  <LocationSettings locationId={id} />
                </React.Fragment>
              }
            />,
          ]}
          <Route
            path="assets"
            element={
              <React.Fragment>
                <AssetManagement locationId={id} title={`Uploads`} />
              </React.Fragment>
            }
          />
          <Route
            path="asset-library"
            element={
              <React.Fragment>
                <AssetManagement locationId={id} library />
              </React.Fragment>
            }
          />
          {info.variety === locationVariety.station && [
            <Route
              path="play"
              element={
                <div>
                  <StationFeedLoaderButton locationId={id} />
                  <StationPlays locationId={id} />
                </div>
              }
            />,
            <Route
              path="song-categories"
              element={
                <div>
                  <SongQueryManagement locationId={id} />
                </div>
              }
            />,
          ]}
          <Route
            path="efforts"
            element={
              <React.Fragment>
                <EffortManagement locationId={id} showCreate={false} />
              </React.Fragment>
            }
          />
          <Route
            path="look-and-feel"
            element={
              <React.Fragment>
                {info.variety !== locationVariety.dealer ? (
                  <Tabs
                    variant="outline"
                    defaultValue="mixer"
                    keepMounted={false}
                  >
                    <Tabs.List mr="xs">
                      <Tabs.Tab value="mixer">Mixer Settings</Tabs.Tab>
                      <Tabs.Tab value="email">Email Settings</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="mixer">
                      <EntitySettingsCode locationId={id} />
                    </Tabs.Panel>
                    <Tabs.Panel value="email">
                      <EmailAppConfig
                        locationId={id}
                        name={info.name}
                        color={
                          info.settings_code &&
                          info.settings_code.settings &&
                          info.settings_code.settings.settings
                            ? info.settings_code.settings.settings.color
                            : "blue"
                        }
                      />
                    </Tabs.Panel>
                  </Tabs>
                ) : (
                  <>
                    <Title order={5} mb="xs" mt="xs">
                      Email Settings
                    </Title>
                    <EmailAppConfig
                      locationId={id}
                      name={info.name}
                      color={
                        info.settings_code &&
                        info.settings_code.settings &&
                        info.settings_code.settings.settings
                          ? info.settings_code.settings.settings.color
                          : "blue"
                      }
                    />
                  </>
                )}
              </React.Fragment>
            }
          />
          <Route
            path="geospots"
            element={
              <div>
                <GeospotManagement locationId={id} />
              </div>
            }
          />
          {postmarkServerId && info.allow_email_marketing && (
            <Route
              path="bulk-communication"
              element={
                <div>
                  <BulkCommunicationManagement locationId={id} />
                </div>
              }
            />
          )}
        </Routes>
      )}
    </div>
  );
}

function LocationStatus({ locationId, fetchData, status }) {
  function changeStatus() {
    axios
      .get(`/locations/${locationId}/change-status/`)
      .then(() => {
        toast.success("Updated!");
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      <Switch
        color="green"
        description={
          status === 1
            ? "Turn off to disable this location but preserve all of their data."
            : "Turn on to reactivate this location."
        }
        label="Activation Status"
        onClick={() => changeStatus()}
        checked={status === 1}
      />
    </React.Fragment>
  );
}
